import { MEVETH_CONTRACT } from '@/constants/addresses';
import { depositedETHBalance, mevETHBalance } from '@/layout/FloatingHeader';
import { device } from '@/styles/common';
import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { formatEther } from 'viem';
import { erc4626ABI, useAccount, useContractRead } from 'wagmi';
import StakingButton from './StakingButton';

function ExtraInfoPluStakeButton() {
  const [isMobile, setIsMobile] = useState(true);
  const [rewards, setRewards] = useState(0n);
  const [balance, setBalance] = useState(0n);
  const [assets, setAssets] = useState(0n);
  const { address } = useAccount();
  const [mevETHBalanceAtom, setMevETHBalance] = useAtom(mevETHBalance);
  const [depositedETHBalanceAtom, setDepositedETHBalance] = useAtom(depositedETHBalance);
  const [currentMevETHRate, setCurrentMevETHRate] = useState(1015326952145897461n);
  const [currentAssets, setCurrentAssets] = useState(0n);

  const { data: userMevETHBalance, error: errorMevETHBalance } = useContractRead({
    address: MEVETH_CONTRACT,
    abi: erc4626ABI,
    functionName: 'balanceOf',
    args: [address || '0x0'],
    enabled: Boolean(address),
    watch: true,
  });

  const {
    data: userConvertToAssets,
    error: errorConvertToAssets,
    isSuccess: isSuccessConvertToAssets,
  } = useContractRead({
    address: MEVETH_CONTRACT,
    abi: erc4626ABI,
    functionName: 'convertToAssets',
    args: [userMevETHBalance || 1000000000000000000n],
    enabled: true,
    watch: true,
  });
  const { data: mevETHRate } = useContractRead({
    address: '0xf518f2EbeA5df8Ca2B5E9C7996a2A25e8010014b',
    abi: [
      {
        inputs: [],
        name: 'getRate',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
      },
    ],
    functionName: 'getRate',
    enabled: true,
    watch: false,
  });
  const handleResize = () => {
    setIsMobile(window.matchMedia('(max-width: 768px)').matches);
  };
  useEffect(() => {
    setIsMobile(window.matchMedia('(max-width: 768px)').matches);
    window.addEventListener('resize', handleResize, false);
  }, []);
  useEffect(() => {
    if (userConvertToAssets) {
      console.log('userMevETHBalance 111', userMevETHBalance);
      console.log('userConvertToAssets 111', userConvertToAssets);
      let newRewards = userConvertToAssets - 1000000000000000000n;
      if (userMevETHBalance) {
        newRewards = userConvertToAssets - userMevETHBalance;
      }
      setRewards(newRewards);
      console.log('assets', userConvertToAssets);
      setBalance(userMevETHBalance || 1000000000000000000n);
      setMevETHBalance(
        userMevETHBalance ? parseFloat(formatEther(userMevETHBalance)).toFixed(3).toString() : '0',
      );
      setDepositedETHBalance(
        userMevETHBalance
          ? parseFloat(formatEther(userConvertToAssets)).toFixed(3).toString()
          : '0',
      );
      setAssets(userConvertToAssets);
    }
    if (mevETHRate) {
      setCurrentMevETHRate(mevETHRate as unknown as bigint);
    }
    if (assets) {
      setCurrentAssets(assets);
    }
  }, [userMevETHBalance, userConvertToAssets, mevETHRate, assets]);

  console.log('rewardsiiii', rewards, mevETHRate, assets);

  return (
    <Container>
      {!isMobile ? (
        <ExtraInfo>
          <a
            href="#"
            color="red"
            title={`1 mevETH = ${parseFloat(formatEther(currentMevETHRate)).toFixed(
              balance ? 5 : 1,
            )} ETH`}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <div style={{ alignSelf: 'start', marginRight: 'auto' }}>
              {`${parseFloat(formatEther(balance)).toFixed(
                balance === 1000000000000000000n ? 1 : 3,
              )}`}{' '}
            </div>
            <img
              style={{ alignSelf: 'start', marginRight: 'auto' }}
              src="/svg/MEV-logo-token.SVG"
              width="24px"
              height="24px"
            />
            <div> {` = ${parseFloat(formatEther(currentAssets)).toFixed(3)}`}</div>
            <img
              style={{ alignSelf: 'start', marginRight: 'auto' }}
              src="/svg/eth.svg"
              width="24px"
              height="24px"
            />
          </a>

          <ExtraLink target="_blank" href="https://x.com/mevdotio">
            𝕏
          </ExtraLink>
        </ExtraInfo>
      ) : null}
      <StakingButton style={{ marginTop: 3 }} href="/stake">
        Start staking ETH
      </StakingButton>
    </Container>
  );
}
const ExtraLink = styled.a`
  color: #fff;
  margin-right: 20px;
  margin-left: 40px;
`;

const Container = styled.div`
  display: flex;
`;

const ExtraInfo = styled.span`
  color: #fff;
  height: 38px;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  cursor: pointer;

  @media ${device.mobileM} {
    padding: 0 14px;
  }
`;

export default ExtraInfoPluStakeButton;
