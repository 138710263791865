import { MEVETH_CONTRACT } from '@/constants/addresses';
import { UserData } from '@/pages/api/invites/getCurrentProfile';
import { device } from '@/styles/common';
import * as Popover from '@radix-ui/react-popover';
import { atom, useAtom } from 'jotai';
import Link from 'next/link';
import React, { use, useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { formatEther, parseEther } from 'viem';
import { erc4626ABI, useAccount, useContractRead } from 'wagmi';

export const mevETHBalance = atom('0');
export const depositedETHBalance = atom('0');

function FloatingHeader() {
  const [isHome, setIsHome] = useState(false);
  const [isMobile, setIsMobile] = useState(window.matchMedia('(max-width: 768px)').matches);
  const [rewards, setRewards] = useState(0n);
  const [balance, setBalance] = useState(0n);
  const [assets, setAssets] = useState(0n);
  const { address } = useAccount();
  const [mevETHBalanceAtom, setMevETHBalance] = useAtom(mevETHBalance);
  const [depositedETHBalanceAtom, setDepositedETHBalance] = useAtom(depositedETHBalance);
  const [user, setUser] = useState<UserData | null>(null);

  const { data: userMevETHBalance, error: errorMevETHBalance } = useContractRead({
    address: MEVETH_CONTRACT,
    abi: erc4626ABI,
    functionName: 'balanceOf',
    args: [address || '0x0'],
    enabled: Boolean(address),
    watch: true,
  });

  const {
    data: userConvertToAssets,
    error: errorConvertToAssets,
    isSuccess: isSuccessConvertToAssets,
  } = useContractRead({
    address: MEVETH_CONTRACT,
    abi: erc4626ABI,
    functionName: 'convertToAssets',
    args: [userMevETHBalance || 1000000000000000000n],
    enabled: true,
    watch: true,
  });
  const { data: mevETHRate } = useContractRead({
    address: '0xf518f2EbeA5df8Ca2B5E9C7996a2A25e8010014b',
    abi: [
      {
        inputs: [],
        name: 'getRate',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
      },
    ],
    functionName: 'getRate',
    enabled: true,
    watch: false,
  });
  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    console.log('storedUser', storedUser);

    if (storedUser) {
      const json = JSON.parse(storedUser);
      setUser(json);
    }
  }, []);
  useEffect(() => {
    if (userConvertToAssets) {
      console.log('userMevETHBalance 111', userMevETHBalance);
      console.log('userConvertToAssets 111', userConvertToAssets);
      let newRewards = userConvertToAssets - 1000000000000000000n;
      if (userMevETHBalance) {
        newRewards = userConvertToAssets - userMevETHBalance;
      }
      setRewards(newRewards);
      console.log('assets', userConvertToAssets);
      setBalance(userMevETHBalance || 1000000000000000000n);
      setMevETHBalance(
        userMevETHBalance ? parseFloat(formatEther(userMevETHBalance)).toFixed(3).toString() : '0',
      );
      setDepositedETHBalance(
        userMevETHBalance
          ? parseFloat(formatEther(userConvertToAssets)).toFixed(3).toString()
          : '0',
      );
      setAssets(userConvertToAssets);
    }
  }, [userMevETHBalance, userConvertToAssets]);

  useEffect(() => {
    setIsHome(window.location.pathname === '/');
    window
      .matchMedia('(max-width: 768px)')
      .addEventListener('change', (e) => setIsMobile(e.matches));
  }, []);
  console.log('rewards', rewards);
  const onClick = isHome
    ? () =>
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
    : undefined;

  return (
    <FloatingHeaderContainer>
      <HeaderAnchor onClick={onClick} href="/" text="Home" />
      {isMobile ? (
        <Popover.Root>
          <Popover.Trigger asChild>
            <Anchor> Explore </Anchor>
          </Popover.Trigger>
          <NavMenuMobile avoidCollisions={true} sideOffset={13}>
            <InformativeSection>
              {/* <HeaderAnchor href="/roadmap" text="Roadmap" menu={true} />
              <HeaderAnchor href="/comparison" text="Compare" menu={true} />*/}
              <HeaderAnchor href="/team" text="Team" menu={true} />
              <HeaderAnchor href="/redeemCreamETH2" text="Migrate" menu={true} />
            </InformativeSection>
            <UseProtocolSection>
              {/*<HeaderAnchor href="/rewards" text="Rewards" menu={true} />*/}
              <HeaderAnchor href="/stake" text="mevETH" menu={true} />
              <HeaderAnchor href="https://blog.mev.io" text="Blog" />
              <HeaderAnchor href="/redemptions" text="Redemptions" menu={true} />
              {/*<HeaderAnchor
                href={`/mana${user === null ? '/invites' : ''}`}
                text="Mana"
                newItem={true}
              />
            <HeaderAnchor href="/mana/leaderboard" text="Leaderboard" newItem={true} />*/}

              {/*<HeaderAnchor href="/enFOLD" text="enFOLD" menu={true} />*/}
            </UseProtocolSection>
            <Popover.Arrow />
          </NavMenuMobile>
        </Popover.Root>
      ) : (
        <>
          {/* <HeaderAnchor href="/roadmap" text="Roadmap" />
          <HeaderAnchor href="/comparison" text="Compare" />*/}
          <HeaderAnchor href="/stake" text="mevETH" />
          <HeaderAnchor href="/redemptions" text="Redemptions" />
          {/*<HeaderAnchor
            href={`/mana${user === null ? '/invites' : ''}`}
            text="Mana"
            newItem={true}
          />
        <HeaderAnchor href="/mana/leaderboard" text="Leaderboard" newItem={true} />*/}

          {/*<HeaderAnchor href="/rewards" text="Rewards" />*/}
          {/*<HeaderAnchor href="/enFOLD" text="enFOLD" />*/}
        </>
      )}
    </FloatingHeaderContainer>
  );
}

function HeaderAnchor({
  href,
  text,
  target,
  onClick,
  color,
  menu,
  alt,
  newItem,
}: {
  href: string;
  text: string;
  color?: string;
  target?: string;
  onClick?: () => void;
  menu?: boolean;
  alt?: string;
  newItem?: boolean;
}) {
  if (onClick) {
    return <Anchor onClick={onClick}>{text}</Anchor>;
  }
  if (menu) {
    return (
      <Link target={target} href={href} title={alt}>
        <MenuItem color={color}>{text}</MenuItem>
      </Link>
    );
  }
  if (newItem) {
    return (
      <Link target={target} href={href} color={color} title={alt}>
        <span>
          <span style={{ color }}>{text}</span>
          <NewBox>NEW</NewBox>
        </span>
      </Link>
    );
  }
  return (
    <Link target={target} href={href} color={color} title={alt}>
      <Anchor color={color}>{text}</Anchor>
    </Link>
  );
}

const NewBox = styled.span`
  background: #185c64;
  margin-left: 5px;
  padding: 2px 4px;
  border-radius: 5px;
  border: 1px solid #1c6970;
  color: #42d8e2;
  font-weight: 600;
`;

const FloatingHeaderContainer = styled.div`
  position: fixed;

  background: #13494f;
  z-index: 1000;
  top: 18px;
  left: 50%;

  transform: translateX(-50%);
  border: 1px solid #254e53;
  height: 44px;
  border-radius: 64px;
  padding: 0 24px;
  gap: 16px;

  display: flex;
  justify-content: center;
  align-items: center;

  @media ${device.mobileM} {
    top: unset;
    bottom: 24px;

    gap: 20px;
    border-radius: 12px;
  }
`;

const Anchor = styled.div<{ color?: string }>`
  size: 14px;
  cursor: pointer;
  @media ${device.mobileM} {
    white-space: nowrap;
  }
  &:hover {
    color: #00bfff;
  }
  transition: color 0.2s ease-in-out;
`;

const slideDownAndFade = keyframes`
  0% { opacity: 0; transform: translateY(-2px); }
  100% { opacity: 1; transform: translateY(0); }
`;

const NavMenuMobile = styled(Popover.Content)`
  border: 1px solid #1d1f2d;
  height: 100%;
  width: 100%;
  border-radius: 24px;
  padding: 16px;
  gap: 0px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  text-align: center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  background: #010314;
  animation-name: ${slideDownAndFade};
  animation-duration: 0.5s;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;

  :focus {
    outline: none;
  }
`;
const MenuItem = styled.div<{ color?: string }>`
  ${({ color = 'red' }) => color};
  padding: 16px 20px;
  font-size: 14px;
  cursor: pointer;
  &:hover {
    color: #00bfff;
  }
  transition: color 0.2s ease-in-out;
`;

const InformativeSection = styled.div`
  width: 100%;
  height: 100%;
`;

const UseProtocolSection = styled(InformativeSection)``;

export default FloatingHeader;
