/**
 * @author Manifold Finance, Inc.
 * @date July, 12, 2023
 * @license GPL-3.0
 */

// import { Inter } from "@next/font/google";
import HeroTopFold from '@/components/home/HeroTopFold';
import MiddleWithCards from '@/components/home/MiddleWithCards';
import DefaultLayout from '@/layout/DefaultLayout';
import React from 'react';

export default function Home() {
  return (
    <DefaultLayout>
      <HeroTopFold />
      <MiddleWithCards />
    </DefaultLayout>
  );
}
