import { device } from '@/styles/common';
import Spline from '@splinetool/react-spline';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { HeaderContainer, LogoImage } from '../general/Card';
import ExtraInfoPluStakeButton from '../general/ExtraInfoPluStakeButton';
import StakingButton from '../general/StakingButton';

const splineUrl = 'https://prod.spline.design/l6IIuP2k6FsAfyjs/scene.splinecode';

function HeroTopFold() {
  function onLoad(splineApp: any) {
    splineApp.emitEvent('keyDown', 'a7186aec-6618-4997-b318-ef021f5cc885');
  }

  return (
    <TopFold>
      <HeaderContainer>
        <Link href="/">
          <LogoImage src="/svg/MEV Text White.svg" alt="Manifold LSD" />
        </Link>
        {/* TODO Made sticky on scroll! */}
        <ExtraInfoPluStakeButton />
      </HeaderContainer>
      <MainContent>
        <LeftContainer>
          <div>MEV Optimized Liquid Staking</div>
          <div>
            Ethereum's next-generation seamless liquid staking protocol. Built from the ground up
            for MEV capture and Validator rewards.
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <StakeNowButton href="/stake">Stake now</StakeNowButton>
            <LearnMoreButton href="#about">Learn more</LearnMoreButton>
          </div>
        </LeftContainer>
        <SplineContainer>
          <Spline onLoad={onLoad} scene={splineUrl} />
        </SplineContainer>
      </MainContent>
    </TopFold>
  );
}

const TopFold = styled.div`
  position: relative;

  border-bottom-left-radius: 38px;
  border-bottom-right-radius: 38px;
  width: calc(100vw - 48px);
  height: calc(100vh - 8px);
  align-self: center;
  overflow: hidden;

  @media ${device.mobileL} {
    width: 100vw;
    height: auto;
    min-height: 100vh;
  }

  @media ${device.mobileM} {
    min-height: unset;
  }
`;

// Main
const MainContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 90%;

  @media ${device.mobileL} {
    flex-direction: column;
  }
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  min-height: 90vh;

  gap: 8px;
  padding-left: 7.5%;

  > div:first-child {
    font-size: 70px;
    font-weight: 500;
    max-width: 590px;
  }

  > div:nth-child(2) {
    max-width: 550px;
    font-size: 16px;
    font-weight: 400;
    color: #ececfa;
    line-height: 1.75;
  }

  @media ${device.mobileL} {
    padding: 0;
    padding-top: 5%;
    min-height: auto;
    max-width: 70vw;

    align-items: center;

    > div:first-child {
      max-width: unset;
      text-align: center;
    }

    > div:nth-child(2) {
      text-align: center;
      max-width: unset;
    }
  }

  @media ${device.mobileM} {
    padding-top: 12%;
    max-width: 85vw;
    > div:first-child {
      font-size: 50px;
    }
  }

  @media ${device.mobileS} {
    padding-top: 18%;
    > div:first-child {
      font-size: 32px;
    }
    > div:nth-child(2) {
      font-size: 14px;
      line-height: 1.4;
    }

`;

const StakeNowButton = styled.a`
  background: #ffffff;
  color: #000000;
  border-radius: 20px;
  height: 38px;
  padding: 0 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  margin-top: 8px;
  cursor: pointer;
  @media ${device.mobileL} {
    margin-bottom: 20px;
  }
`;
const LearnMoreButton = styled.a`
  height: 38px;
  padding: 0 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  margin-top: 8px;
  cursor: pointer;

  @media ${device.mobileL} {
    margin-bottom: 20px;
  }
`;

const SplineContainer = styled.div`
  height: 100%;
  width: 50vw;
  @media ${device.mobileL} {
    margin-top: 32px;
    width: 100vw;
    height: 50vh;
  }

  @media ${device.mobileM} {
    margin-top: 0;
  }
`;

export default HeroTopFold;
