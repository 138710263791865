import { colors, device } from '@/styles/common';
import Spline from '@splinetool/react-spline';
import styled from 'styled-components';

const SPLINE_URL_ONE = 'https://prod.spline.design/cAwF-MqShwyeM0ib/scene.splinecode';

const SPLINE_URL_TWO = 'https://prod.spline.design/6GYK8mDK-6rVDjW8/scene.splinecode';

const SPLINE_URL_THREE = 'https://prod.spline.design/QzlWk9oiBBHZnrdC/scene.splinecode';

function CardText({
  title,
  subtitle,
  center,
}: {
  title: string;
  subtitle: string;
  center?: boolean;
}) {
  return (
    <CardTextContainer center={center}>
      <div>{title}</div>
      <div>{subtitle}</div>
    </CardTextContainer>
  );
}

function Card({
  title,
  subtitle,
  center,
  children,
  className,
}: {
  title: string;
  subtitle: string;
  center?: boolean;
  children?: React.ReactNode;
  className?: string;
}) {
  return (
    <CardContainer center={center} className={className}>
      <CardText center={center} title={title} subtitle={subtitle} />
      {children}
    </CardContainer>
  );
}

function Cards() {
  return (
    <>
      <CardRowOne>
        <MainCard
          title={`Liquid Staking meets Lending`}
          subtitle="mevETH is designed to rebalance across staking and lending to maximize yield."
        >
          <SplineContainer>
            <Spline scene={SPLINE_URL_ONE} />
          </SplineContainer>
        </MainCard>
        <MainCard
          title="OpenMEV Architecture"
          subtitle="mevETH captures multiple winning block proposals, meaning validators always earn more than compared with mev-boost."
        >
          <SplineContainer>
            <Spline scene={SPLINE_URL_TWO} />
          </SplineContainer>
        </MainCard>
        <MainCard
          title="Non-Rebasing Design"
          subtitle="mevETH's non-rebasing design means your balance will never decrease, and allows friction-free use with your favorite DeFi protocols."
        >
          <SplineContainer>
            <Spline scene={SPLINE_URL_THREE} />
          </SplineContainer>
        </MainCard>
      </CardRowOne>
    </>
  );
}

const CardRowOne = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 24px 0;
  width: 90vw;

  max-width: 1360px;

  @media ${device.mobileL} {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
  }
`;

const CardContainer = styled.div<{ center?: boolean }>`
  background: linear-gradient(180deg, rgba(2, 20, 23, 0.72) 15%, rgba(0, 0, 0, 0.05) 75%);
  border: 1.5px solid rgba(34, 44, 60, 0.8);
  border-radius: 16px;
  position: relative;

  &:hover {
    border-color: rgba(48, 50, 70, 1);
  }
  transition: border-color 0.2s ease-in-out;

  overflow: hidden;
`;

const CardTextContainer = styled.div<{ center?: boolean }>`
  position: absolute;
  left: 24px;

  bottom: ${({ center }) => (center ? '30%' : '28px')};
  max-width: ${({ center }) => (center ? '400px;' : 'auto')};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  gap: 10px;
  & > div:first-child {
    font-weight: 600;
    font-size: 22px;
  }
  & > div:last-child {
    font-weight: 400;
    max-width: 90%;
    ${colors.grayShine}
  }

  z-index: 1;
`;

const MainCard = styled(Card)`
  width: calc(33% - 16px);
  min-height: 382px;
  aspect-ratio: 1/1.1;

  @media ${device.laptopS} {
    aspect-ratio: 1/1.5;
  }

  @media ${device.mobileL} {
    width: calc(100%);
    aspect-ratio: 1/1.1;
  }
`;

const SplineContainer = styled.div`
  height: 100%;
  position: absolute;
  width: 100%;
  left: 0;
  // background: green;
  overflow: hidden;
  border-radius: 16px;
`;
export default Cards;
