import { colors } from '@/styles/common';
import React from 'react';
import styled from 'styled-components';
import AvailableToday from './AvailableToday';
import Cards from './Cards';
import WhySection from './WhySection';

function MiddleWithCards() {
  return (
    <MiddleWithCardsContainer id="about">
      <Title>Maintain the Liquidity of your ETH</Title>
      <Subtitle>
        mevETH is the non-custodial liquid staking receipt that offers the highest potential rewards
        today.
      </Subtitle>
      <Cards />
      {/* <WhySection /> */}
      <AvailableToday />
    </MiddleWithCardsContainer>
  );
}

const MiddleWithCardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 36px;
  text-align: center;
  max-width: 388px;
  padding: 0 24px;
  padding-top: 72px;

  ${colors.whiteShine}
`;

const Subtitle = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 1.85;
  text-align: center;
  max-width: 480px;
  margin-top: 18px;
  padding: 0 24px;
  margin-bottom: 48px;

  ${colors.grayShine}
`;

export default MiddleWithCards;
